// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-picks-books-js": () => import("./../../../src/pages/picks/books.js" /* webpackChunkName: "component---src-pages-picks-books-js" */),
  "component---src-pages-picks-index-js": () => import("./../../../src/pages/picks/index.js" /* webpackChunkName: "component---src-pages-picks-index-js" */),
  "component---src-pages-picks-newsletters-js": () => import("./../../../src/pages/picks/newsletters.js" /* webpackChunkName: "component---src-pages-picks-newsletters-js" */),
  "component---src-pages-picks-podcasts-js": () => import("./../../../src/pages/picks/podcasts.js" /* webpackChunkName: "component---src-pages-picks-podcasts-js" */),
  "component---src-pages-picks-software-js": () => import("./../../../src/pages/picks/software.js" /* webpackChunkName: "component---src-pages-picks-software-js" */),
  "component---src-pages-projects-helping-hands-js": () => import("./../../../src/pages/projects/helping-hands.js" /* webpackChunkName: "component---src-pages-projects-helping-hands-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-sdc-system-js": () => import("./../../../src/pages/projects/sdc-system.js" /* webpackChunkName: "component---src-pages-projects-sdc-system-js" */),
  "component---src-pages-workshops-index-js": () => import("./../../../src/pages/workshops/index.js" /* webpackChunkName: "component---src-pages-workshops-index-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

